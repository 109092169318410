@mixin opc__update__page-checkout {
  .checkout-progress-bar {
    margin-top: 20px;
    &__list {
      overflow: hidden;
      counter-reset: step;
      width: 100%;
      margin: 0;
      padding-top: 2px;
    }
    &__list-item {
      color: $color-gray;
      width: 33.33%;
      float: left;
      position: relative;
      text-align: center;
      font-size: 14px;
      line-height: 19px;
      a {
        border-bottom: none;
        color: $color-inactive-panel;
        text-decoration: none;
        &:hover,
        &:visited {
          color: $color-inactive-panel;
          text-decoration: none;
        }
      }
      &:before {
        content: counter(step);
        counter-increment: step;
        width: 18px;
        line-height: 14px;
        font-size: 8px;
        display: block;
        color: $color-inactive-panel;
        background: $color-inactive-panel;
        margin: 0 auto 5px auto;
        border: 2px solid $color-white;
        @include border-radius(11px);
        @include box-shadow(0 0 0 1px $color-inactive-panel);
      }
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: $color-progress-line;
        position: absolute;
        left: 0;
        top: 8px;
        z-index: -1;
      }
      &:first-child {
        a {
          margin-left: -30%;
        }
        &:before {
          margin-left: 25%;
        }
        &:after {
          left: 25%;
        }
      }
      &:last-child {
        a {
          float: right;
          margin-right: 15%;
        }
        &:before {
          margin-right: 25%;
        }
        &:after {
          left: -25%;
        }
      }
      &--complete {
        color: $color-active-panel;
        font-family: $font--futura-medium;
        a {
          color: $color-active-panel;
          &:hover,
          &:visited {
            color: $color-active-panel;
          }
        }
        &:before {
          background: $color-active-panel;
          color: $color-active-panel;
        }
      }
    }
  }
  .confirm {
    .checkout__header {
      border-bottom: 0px;
      h1 {
        text-align: center;
      }
    }
    .checkout-confirmation-page {
      #registration-panel {
        border-bottom: 0;
        padding-bottom: 0;
        .loyalty-join {
          margin-bottom: 20px;
        }
        .fs {
          &.loyalty_join {
            margin-left: 0px;
          }
        }
      }
      #sign-in-confirmation-panel {
        .form-item {
          margin-bottom: 10px;
          &.password {
            label {
              display: none;
            }
          }
          input {
            width: 100%;
          }
        }
      }
      .example-user-email-label {
        font-family: $font--futura-medium;
      }
      .mobile-section {
        .or-text {
          position: relative;
          float: none;
          clear: none;
          margin: 20px 0;
          padding: 0;
          text-align: center;
          border-bottom: 1px solid $color-light-gray;
          p {
            position: absolute;
            top: -18px;
            left: 0;
            right: 0;
            width: 35px;
            height: 20px;
            margin: 10px auto;
            padding: 0;
            line-height: 16px;
            background-color: $color-white;
            text-transform: uppercase;
            color: $color-black;
          }
        }
      }
      .back-to-home-container {
        padding: 0 1em;
        a {
          width: 100%;
          display: block;
          border: 1px solid $color-black;
          text-align: center;
          padding: 11px 0;
          margin-bottom: 30px;
          font-family: $font--futura-medium;
          text-transform: uppercase;
          text-decoration: none;
        }
      }
      .checkout__sidebar {
        display: none;
      }
    }
    .page-header {
      .page-header__utils {
        .mobile-checkout-back {
          display: none;
        }
      }
    }
    #confirmation-panel {
      .confirmation-signedin {
        background-color: $color-light-gray;
      }
    }
    .checkout__content {
      .registration-panel {
        &__create-account {
          background-color: $color-white;
        }
      }
    }
    .expando-block {
      &.sms-promotions {
        display: none;
      }
    }
  }
  .opc__registration {
    &.index {
      .checkout {
        &__panel {
          &.collapsed,
          &.finished,
          &.display {
            display: none;
          }
        }
      }
      .checkout {
        &-header,
        &-header-review,
        &-progress-bar {
          display: none;
        }
        &__panel {
          border-top: 0px;
        }
      }
      .expando-block {
        padding: 1em 0 0 0;
        margin-bottom: 1em;
      }
      .sms-promotions {
        .checkout__panel--heading {
          width: 100%;
        }
      }
      .mobile-section {
        display: none;
      }
    }
  }
}
