.opc__payment {
  .payment-container {
    .alternate-address-links {
      display: none;
    }
    .payment-control-choose {
      padding-top: 1em;
    }
    .address {
      .form-container {
        margin-top: 10px;
        .existing-address {
          .address-menu-container {
            .select-menu {
              select {
                width: 100%;
              }
              .button,
              .edit-address-link,
              .address-options__address-container {
                display: none;
              }
            }
          }
          .delivery-address {
            line-height: 1.2;
            margin-top: 0;
          }
          .new-address {
            margin-top: 0;
            .address-form {
              &__city-state-zip {
                .state {
                  label {
                    display: none;
                  }
                }
              }
              &__country {
                label {
                  display: none;
                }
              }
            }
          }
        }
        .address-to-use {
          &.select {
            padding-bottom: 0;
          }
          span,
          label {
            display: block;
          }
        }
      }
    }
  }
}

.quiz-landing-page-formatter {
  .quiz-landing-page-formatter__results {
    .quiz-landing-page-formatter__body {
      .retake-quiz {
        width: 34%;
        font-size: 17px;
      }
      .retake-quiz::before {
        left: -9px;
      }
      .retake-quiz::after {
        left: -10px;
      }
    }
  }
}

.form-formatter__form {
  .grid--2 .mantle-form-options-field__item {
    height: 143px;
    font-size: 15px;
  }
}
