@mixin opc__update__checkout-shared {
  //end of opc__update__checkout-shared
  .viewcart,
  .samples,
  .wp_samples,
  .index,
  .confirm {
    .page-header {
      .page-header__utils {
        @if not is_2020_nav_redesign() {
          text-align: center;
          position: relative;
          &__logo {
            float: none;
            line-height: 34px;
            @if $cr18 == true {
              float: $ldirection;
            }
          }
        }
        .block {
          .menu.menu--lvl-1,
          ul.menu__list.menu__list--lvl-1 {
            display: none;
            @if $cr18 == true {
              display: block;
            }
          }
          @if is_2020_nav_redesign() {
            .menu.menu--lvl-1 {
              border-bottom: 1px solid $color-2020-nav-mobile-border;
            }
            ul.menu__list.menu__list--lvl-1 {
              display: flex;
              justify-content: space-evenly;
            }
          }
        }
        .gnav-util {
          &--hamburger,
          &--account,
          &--live-chat,
          &--search,
          &--cart {
            display: none;
            @if $cr18 == true {
              display: block;
            }
          }
        }
        .gnav-util--search-v2 {
          &.gnav-util {
            .gnav-util__content__inner {
              form {
                &.gnav-search {
                  input[type='search'] {
                    @if not is_2020_nav_redesign() {
                      margin-top: -29px;
                    }
                    @include breakpoint($landscape-up) {
                      margin-top: 2px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &--fixed {
        .page-header__utils {
          border-bottom: 1px solid $color-gray;
        }
      }
    }
    .page-footer {
      &__primary,
      &__secondary .country-chooser {
        display: none;
      }
      &__secondary {
        padding-bottom: 6em;
        .menuref,
        .basic.copyright {
          display: none;
        }
        .footer-link {
          color: $color-black;
          padding: 10px 0;
          width: 100%;
          text-align: center;
          display: inline-block;
          font-size: 20px;
          background: $color-white;
          margin-bottom: 15px;
          font-weight: bold;
          letter-spacing: 1px;
          font-family: $font--subheading;
          &-container {
            width: 48%;
            display: inline-block;
            float: left;
            &.livechat {
              margin-right: 4%;
            }
          }
          .icon {
            padding-right: 5px;
          }
        }
        .footer-links__choosers {
          display: none;
        }
        .policy-text {
          color: $color-white;
          text-align: center;
          display: block;
          padding: 20px 0;
          border-bottom: 1px solid $color-gray;
          margin-bottom: 20px;
          font-size: 25px;
          letter-spacing: 2px;
          font-family: $font--subheading;
        }
        .secure-content {
          margin: 0 auto;
          color: $color-gray;
          font-size: 20px;
          clear: both;
          text-align: center;
          display: block;
          float: left;
          width: 100%;
          letter-spacing: 1px;
          font-family: $font--subheading;
          .icon {
            margin: 0 5px 3px 0;
          }
        }
        .copyright {
          font-size: 13px;
          margin-bottom: 5px;
        }
        .footer-checkout {
          display: block;
        }
      }
    }
  }
  .viewcart {
    .signin-overlay {
      .page-header__utils {
        text-align: center;
        position: relative;
        padding: 10px 0;
        &__logo {
          float: none;
          line-height: 34px;
          @if $cr18 == true {
            float: $ldirection;
          }
        }
      }
      .mobile-checkout-back {
        display: block;
        background: url(/media/export/cms/homepage/nav-arrows.png) 12px 9px;
        background-repeat: no-repeat;
        width: 30px;
        height: 50px;
        position: absolute;
        top: 2%;
        $ldirection: 2%;
      }
    }
  }
  .checkout {
    &__panel {
      &.collapsed,
      &.finished,
      &.display {
        .opc__payment &,
        .opc__shipping & {
          display: none;
        }
      }
    }
    .checkout__content {
      #promo-panel {
        border-bottom: 1px solid $color-light-gray;
        box-shadow: 0 3px 10px -5px $color-medium-gray;
        padding: 0;
        .messages {
          padding: 0.5em;
          .single-message {
            &:first-child {
              padding: 0px 0px 5px 0px;
            }
            line-height: 15px;
            font-size: 12px;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }
    }
    .gift {
      &-options {
        .giftwrap {
          text-align: left;
          img,
          .button {
            display: none;
          }
        }
      }
      &-options-display {
        padding-bottom: 1.5em;
        border-bottom: 1px solid $color-light-gray;
        p {
          margin-top: 0;
          margin-bottom: 0;
        }
        .giftwrap-no {
          margin-bottom: 1em;
        }
        .checkout__panel {
          &--heading {
            width: 100%;
            .link {
              float: right;
              line-height: 1.5em;
              text-decoration: underline;
            }
          }
          &--giftwrapdetails {
            margin-bottom: 15px;
            li {
              list-style-type: disc;
              margin-left: 25px;
            }
          }
        }
      }
      &-label {
        font-family: $font--futura-medium;
        text-transform: capitalize;
      }
      &-box {
        label,
        img {
          float: left;
        }
        label {
          line-height: 94px;
          padding-left: 20px;
        }
      }
      &-options__container {
        .giftwrap__options {
          .gift-box {
            float: left;
          }
        }
      }
    }
    &__footer {
      display: none;
    }
    .expando-block__icon {
      position: relative;
      float: right;
      display: inline-block;
      font-size: 18px;
      right: 0;
      top: -4px;
    }
    .messages {
      .single-message {
        &.MESSAGE {
          color: $color-red;
          margin-bottom: 15px;
        }
      }
    }
    .warning-message {
      font-size: 0.8em;
      text-transform: uppercase;
      text-align: center;
    }
    #viewcart-panel {
      .cart-items {
        &__item {
          margin-bottom: 1em;
          border-bottom: 1px solid $color-light-gray;
        }
        &__item:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
  .opc {
    &__signin,
    &__shipping,
    &__payment {
      .checkout__content {
        .checkout-header-review {
          display: none;
        }
      }
    }
  }
  .mobile-hidden {
    display: none !important;
    @include breakpoint($medium-up) {
      display: block !important;
    }
  }
  .pc-hidden {
    @include breakpoint($medium-up) {
      display: none !important;
    }
  }
  .adpl {
    .invalid_marker {
      bottom: 1.2em;
      right: 3px;
      font-size: 1.4em;
    }
  }
  #review-panel,
  #shipping-panel {
    .review-panel {
      &__loyalty-points {
        padding: 1em;
        .rewards_icon_container {
          .icon.icon--my-origins {
            margin: 0 20px 20px 0;
          }
          .origins_reward_icon {
            top: 30px;
            left: 28px;
          }
        }
      }
    }
    .expando-block {
      padding: 0;
    }
  }
  @if $cr18 == true {
    .index,
    .samples,
    .wp_samples,
    .confirm {
      .page-header {
        .gnav-util--groupby-search,
        .gnav-util__icon,
        .utility-nav-mob,
        .utility-nav {
          display: none;
        }
        .page-header__utils {
          .mobile-checkout-back {
            top: 10%;
          }
          .gnav-util--search-v2 {
            display: none;
          }
        }
        .page-header__utils__logo {
          float: none;
        }
      }
    }
    .viewcart,
    .index,
    .samples,
    .wp_samples,
    .confirm {
      .footer-copyright {
        display: none;
      }
    }
  }
}
