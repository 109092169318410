@mixin opc__update__checkout-viewcart {
  .opc__signin,
  .opc__cart {
    .checkout {
      &__header {
        border-bottom: 0;
        padding-bottom: 0;
      }
      &__content {
        .gift-options__content,
        .order-summary-panel {
          border-bottom: 0;
          padding: 1em 0 0;
        }
        #viewcart-panel {
          .messages {
            padding-bottom: 0;
            .single-message {
              padding-bottom: 0;
              color: $color-error-msg;
              a {
                color: $color-error-msg;
              }
              &[id^='error_offer_criteria_met'],
              &.success {
                color: $color-success-msg;
                a {
                  color: $color-success-msg;
                }
              }
              &.threshold-messages {
                color: $color-alert-msg;
                a {
                  color: $color-alert-msg;
                }
              }
            }
          }
        }
        #top-viewcart-buttons {
          .checkout-buttons__container {
            display: none;
          }
        }
        #offer-code-panel,
        .gift-options__content {
          position: relative;
          .checkout__panel--heading {
            width: 100%;
          }
        }
        #offer-code-panel {
          padding-bottom: 0;
          #offer_code {
            .button {
              width: 99%;
            }
          }
        }
        .cart-items__item--total {
          margin-top: -20px;
          padding-left: 5px;
          width: 16%;
        }
      }
      &__sidebar {
        display: none;
      }
      &__panel--continue-buttons {
        .checkout-buttons__item {
          width: 100%;
        }
        .paypal-checkout {
          width: 100%;
          height: 45px;
          line-height: 45px;
          margin-top: 10px;
          border: 1px solid $color-black;
          img {
            width: 100px;
            margin-top: 0;
            vertical-align: text-bottom;
          }
        }
        span {
          &.choose_samples_txt {
            display: block;
            margin-bottom: 15px;
          }
        }
        .checkout-buttons__container span.or_text,
        .continue-shopping {
          display: none;
        }
      }
      .cart-items__item--swatch {
        float: none;
      }
    }
    .applepay #bottom-viewcart-buttons {
      display: none;
    }
  }
}
