@mixin opc_update__checkout-review {
  .opc__review {
    #guarantee-panel {
      &.checkout__panel {
        display: none;
      }
    }
    #sign-in-panel,
    #links-panel,
    #gift-card-display {
      display: none;
    }
    .checkout__content {
      border-top: none;
      .checkout-header-review {
        text-align: center;
        display: block;
        margin-top: 30px;
        margin-bottom: 10px;
      }
      #review-panel {
        .email-and-sms-promotions {
          display: none;
        }
      }
      #shipping-panel {
        border-top: none;
        header {
          &.shipping_head {
            display: none;
          }
        }
        .shipping-address {
          &-display {
            border-bottom: 1px solid $color-light-gray;
            margin-bottom: 1em;
            .customer-name {
              font-family: $font--futura-medium;
            }
          }
          &-display__addresses {
            margin: 0;
          }
        }
      }
      #date-display {
        margin: 1em 0;
        border-bottom: 1px solid $color-light-gray;
      }
    }
    .checkout__panel--header > {
      a.edit {
        text-decoration: underline;
      }
    }
    .checkout__sidebar {
      #viewcart-panel {
        margin-bottom: 15px;
        border-bottom: 1px solid $color-light-gray;
      }
      #offer-code-panel {
        position: relative;
        padding: 0.1em 0;
        .checkout__panel--heading {
          width: 100%;
          margin: 0.5em 0;
        }
      }
      .order-summary-panel {
        &__label,
        .selectBox {
          font-family: $font--text;
          letter-spacing: 0;
          font-size: 18px;
          text-transform: none;
        }
        &__total--value,
        &__total--label {
          background: #f0f0f0;
          padding: 9px;
        }
        &__total--label {
          line-height: 1.2;
        }
      }
      .loyalty_panel {
        display: none;
      }
    }
    #payment {
      &-panel {
        border-top: 0;
        padding: 0;
        header.payment-header {
          display: none;
        }
      }
      &-display {
        .customer-name {
          margin-bottom: 3px;
          font-family: $font--futura-medium;
        }
        .image-payment-display {
          float: left;
          width: 10%;
        }
        .card-number,
        .card-expiry-date {
          display: block;
        }
      }
    }
    .registration-panel {
      display: none;
    }
  }
}
