@mixin opc__update__signin-panel {
  .opc__cart,
  .opc__signin {
    section#shipping-panel,
    section#payment-panel,
    section#review-panel,
    section#guarantee-panel,
    .checkout__sidebar,
    .checkout__footer {
      display: none;
    }
    #sign-in-panel {
      border: 0px;
      .email-promotions {
        width: 100%;
        padding-bottom: 0;
        .pc_email_promo_container {
          a {
            display: none;
          }
        }
      }
      a.link {
        font-family: $font--futura-book;
        text-decoration: underline;
        letter-spacing: 0.04em;
        font-weight: normal;
        line-height: 1;
        font-size: 14px;
        color: $color-black;
        cursor: pointer;
      }
    }
    .checkout {
      &-header__title {
        text-align: center;
        margin-bottom: 0;
        font-size: 30px;
        @include font--subheading;
      }
      &__content {
        border-bottom: 0px;
      }
      &__new-user__toggle,
      &__return-user__toggle {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .page-wrapper {
      padding-bottom: 0;
    }
  }
  //end of opc__update__signin-panel
}
