@mixin opc_update__checkout-samples {
  .opc__samples,
  .opc__wp_samples {
    .checkout__panel {
      border: 0;
      &--heading {
        margin-bottom: 15px;
        text-align: center;
        font-size: 30px;
      }
      &--heading--container,
      header {
        border: 0;
        padding-top: 4px;
      }
      &--wp-heading--container {
        padding-top: 11px;
      }
      .checkout-panel {
        &__message {
          text-align: center;
        }
      }
    }
    #samples-panel {
      header {
        border-bottom: 0;
        h2 {
          width: auto;
          margin-bottom: 2px;
        }
      }
      ul.product-list {
        padding: 0;
        li.product {
          &:last-child {
            border-bottom: none;
          }
          &.selected {
            border-bottom: 2px solid $color-black;
          }
          .wp_sample_sku {
            li.sku {
              width: auto;
            }
          }
        }
        .product {
          a.sample-select-button {
            &.button {
              width: 250px;
              margin: 10px auto;
              position: relative;
              top: 10px;
              left: 0px;
            }
          }
        }
      }
      .samples {
        .remaining {
          text-align: center;
        }
        &-buttons-container {
          padding: 0;
          border-bottom: 0;
        }
      }
    }
  }
} // end opc_update__checkout-samples
