@mixin opc_update__checkout-payment {
  .opc__payment {
    #payment-panel {
      border-top: none;
      .checkout__title--header {
        text-align: center;
        display: block;
        padding: 0;
        margin-top: 13px;
        &.payment-method {
          display: none;
        }
      }
      .saved-payments {
        .payment-method-id {
          padding-right: 0;
          &__option {
            padding: 1em;
            border-bottom: 1px solid $color-light-gray;
          }
          input[type='radio'] {
            float: left;
          }
          input[type='radio'] ~ label {
            @include icon('checkbox', before, false, false);
          }
          input[type='radio']:checked ~ label {
            @include icon('checkbox--checked', before, false, false);
          }
          label {
            float: left;
            width: 80%;
          }
        }
        &__display {
          width: 80%;
          float: right;
          .default-marker {
            float: left;
          }
          .payment-cc-info {
            &__customer-name,
            &__cc-image,
            &__card-details {
              float: left;
            }
            &__card-details {
              padding-left: 10px;
              span {
                float: left;
              }
            }
          }
        }
        .cc-info {
          padding: 0;
        }
      }
      .button {
        &.giftcard-check-balance {
          background-color: $color-white;
          color: $color-black;
          width: 99%;
        }
        &.giftcard-apply {
          width: 99%;
        }
      }
    }
    .payment-container {
      .address {
        padding: 0 1em;
        .existing-address {
          .saved-address,
          .delivery-address {
            clear: both;
            margin-top: 15px;
            .customer-name {
              font-family: $font--futura-medium;
            }
          }
          .new-address {
            margin-top: 20px;
          }
        }
      }
      .paypal-address {
        padding: 0 1em 1em 1em;
      }
      .alternate-address-links {
        &__link-wrapper {
          float: right;
          text-decoration: underline;
        }
        &__checkbox-wrapper {
          label {
            display: block;
            float: left;
          }
        }
      }
      input[type='checkbox'] ~ label::before {
        margin-top: 10px;
      }
      a.link {
        text-decoration: underline;
        float: right;
      }
    }
    .payment_method-container {
      .payment-form {
        padding: 1em 1em 0 1em;
        .expires-year,
        .select {
          width: 33%;
          margin-right: 1.75%;
          padding-right: 0.25em;
          float: left;
        }
        .cvv {
          margin-right: 0;
          float: left;
          width: 30%;
          padding-right: 0;
        }
        .do-not-save-payment {
          clear: both;
        }
        select {
          border-radius: 0;
        }
        select:disabled {
          background: $color-light-gray;
          color: $color-light-gray;
        }
        .card-number {
          width: 100%;
          padding-right: 0;
        }
        .default-payment {
          padding: 0;
        }
        .do-not-save-payment,
        .default-payment,
        .paypal-address {
          width: 100%;
        }
      }
      .cc_image_container {
        position: absolute;
        right: 1em;
        width: auto;
        top: 1em;
        ul {
          &.related-media {
            width: auto;
          }
        }
      }
      .payment-control {
        &__add-new-method-link {
          padding: 0 1em 1em 1em;
          border-bottom: 1px solid $color-light-gray;
          .payment-control__anchor {
            text-align: center;
            text-transform: uppercase;
            display: inline-block;
            padding: 10px 0;
            font-family: $font--futura-medium;
            border: 1px solid $color-black;
            width: 100%;
          }
        }
        &-choose {
          padding: 0 1em;
          width: 100%;
          span {
            margin-right: 15px;
          }
        }
      }
    }
    #gift-options-display {
      display: none;
    }
    #payment-giftcard-panel {
      border-top: 1px solid $color-light-gray;
      border-bottom: 1px solid $color-light-gray;
      padding-bottom: 0;
      .checkout__panel--heading {
        width: 100%;
      }
    }
    .checkout {
      &__panel--content {
        padding: 0;
      }
    }
    .payment-giftcard {
      padding: 0 1em;
    }
    .checkout__panel--billing {
      border-top: 1px solid $color-light-gray;
      margin-bottom: 20px;
      .name-fields {
        .form-item {
          width: 49%;
          padding-right: 0;
          &.first-name {
            float: left;
            margin-right: 2%;
          }
          &.last-name {
            float: right;
          }
        }
      }
      .city-state-zip {
        .form-item {
          float: left;
          clear: none;
          margin-right: 0;
          margin-left: 0;
          padding-right: 0;
          &.state {
            width: 33%;
            float: left;
            margin-right: 0;
            padding-right: 0;
            clear: none;
            select {
              border-radius: 0;
            }
          }
          &.city {
            width: 37%;
            float: right;
          }
          &.postal-code {
            width: 27%;
            margin-right: 5px;
          }
        }
      }
      fieldset {
        .form-item {
          padding-right: 0;
        }
      }
      .checkout__panel--header {
        padding: 1em 1em 0 0;
      }
      .alternate-address-links {
        &__checkbox-wrapper {
          float: left;
          width: 62%;
        }
        &__text {
          position: relative;
          top: 5px;
        }
        &__link-wrapper {
          float: right;
          width: 35%;
          text-align: right;
          top: 15px;
          position: relative;
        }
      }
    }
  }
} // end opc_update__checkout-payment
