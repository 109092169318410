@mixin opc__update__product-items {
  .viewcart {
    .checkout__content {
      .single-message {
        color: $color-green;
        &:first-child {
          padding: 1em 0;
        }
      }
      #viewcart-panel {
        .cart-items__item {
          &--price {
            margin-left: 33.33%;
            float: none;
            width: 45%;
          }
          &--qty {
            margin-top: 10px;
            margin-left: 30.33%;
            float: left;
            clear: both;
            select {
              min-width: 3em;
              @if $cr18 == true {
                min-width: 4em;
                font-size: 12px;
              }
            }
          }
          &.sample {
            .cart-items__item--qty {
              clear: right;
            }
          }
          &.donation {
            .cart-items__item--qty {
              @include breakpoint($small-down) {
                margin-left: 0;
                padding-left: 0;
                width: 12%;
              }
            }
          }
          &--thumb {
            @include breakpoint($small-up) {
              width: 33.33%;
            }
          }
          &--total {
            bottom: 3.1em;
            top: auto;
          }
          &--replenishment {
            clear: none;
            float: right;
            width: 48%;
            margin: 9px 0 0 5%;
            padding-top: 0;
            select {
              width: 100%;
              padding: 0 18px 0 9px;
              @if $cr18 == true {
                font-size: 12px;
              }
            }
            &__notice {
              display: none;
            }
          }
          &--remove-form {
            margin-top: 0;
            .remove_link,
            .remove_link_giftcard {
              position: absolute;
              width: 24px;
              height: 24px;
              top: 15px;
              right: 5px;
            }
          }
          .icon {
            color: $color-black;
            font-weight: bolder;
          }
        }
      }
      .order-summary-panel {
        .checkout__panel--content {
          padding: 0 1em;
        }
        &__label {
          text-align: left;
        }
        &__total {
          &--label,
          &--value {
            background: $color-light-gray;
            padding: 5px;
          }
        }
      }
    }
  }
  //end of opc__update__product-items
}
