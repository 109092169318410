@mixin opc_update__checkout-shipping {
  .opc__shipping {
    .checkout__panel--shipping {
      &__edit-address {
        border-bottom: 1px solid $color-light-gray;
        .name-fields {
          .form-item {
            width: 49%;
            padding-right: 0;
            &.first-name {
              float: left;
              margin-right: 2%;
            }
            &.last-name {
              float: right;
            }
          }
        }
        .city-state-zip {
          .form-item {
            float: left;
            clear: none;
            margin-right: 0;
            margin-left: 0;
            padding-right: 0;
            &.state {
              width: 33%;
              float: left;
              margin-right: 0;
              padding-right: 0;
              clear: none;
              select {
                border-radius: 0;
              }
            }
            &.city {
              width: 37%;
              float: right;
            }
            &.postal-code {
              width: 26%;
              margin-right: 7px;
            }
          }
        }
        fieldset {
          .form-item {
            &.shipping-address-options {
              padding-right: 0;
              padding-bottom: 0;
            }
          }
          .address-options {
            input[type='radio'] {
              position: static;
              height: 0px;
              @include icon('checkbox', before, false, false);
            }
            input[type='radio']:checked {
              @include icon('checkbox--checked', before, false, false);
            }
            .button {
              display: none;
            }
            &__address-container {
              border-bottom: 1px solid $color-light-gray;
              height: auto;
              padding: 0 0 20px 30px;
              .default-marker {
                color: $color-green;
              }
              .address-name {
                font-family: $font--futura-medium;
                text-transform: capitalize;
              }
            }
            span.add_new_address {
              label {
                display: block;
                float: left;
                margin-left: 30px;
              }
            }
          }
          .edit-address-link {
            float: right;
            a.link {
              text-decoration: underline;
            }
          }
        }
      }
    }
    #sign-in-panel {
      .checkout__panel--content {
        display: none;
      }
    }
    #payment-panel,
    #review-panel,
    #guarantee-panel {
      &.checkout__panel {
        display: none;
      }
    }
    .checkout__sidebar,
    .update-address-note,
    #gift-options-display {
      display: none;
    }
    .checkout__content {
      #shipping-panel {
        border-top: none;
        .checkout__title--header {
          text-align: center;
          display: block;
          margin-top: 13px;
          &.delivery_head {
            display: none;
          }
          &.shipping_head p {
            display: none;
          }
        }
        .checkout__panel--content {
          .shipping-control__anchor {
            text-align: center;
            text-transform: uppercase;
            display: inline-block;
            padding: 10px 0;
            font-family: $font--futura-medium;
            border: 1px solid $color-black;
            width: 100%;
          }
        }
        .messages {
          .single-message {
            padding-bottom: 20px;
          }
        }
      }
      .email-and-sms-promotions {
        margin-bottom: 20px;
        position: relative;
      }
      .expando-block {
        &.sms-promotions,
        &.gift-options__content {
          padding: 0;
          border-bottom: 0px;
          position: relative;
          .checkout__panel--heading {
            width: 100%;
            margin: 0.5em 0;
          }
        }
        &__content {
          border-bottom: 1px solid $color-light-gray;
        }
      }
    }
    .limited_shipping {
      margin: 15px 20px;
    }
    .checkout__button {
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
    }
    .checkout__panel {
      border-top: none;
    }
  }
  .checkout {
    .adpl {
      input[type='text'],
      input[type='tel'] {
        border-radius: 0;
      }
    }
    .checkout_sticky {
      background-color: $color-white;
      height: 68px;
      position: fixed;
      bottom: 0;
      right: 0px;
      z-index: 99;
      visibility: visible;
      width: 100%;
      @include box-shadow(0 0 0 2px $color-light-gray);
      .mobile_sticky_bottom {
        visibility: visible;
        display: block;
        position: fixed;
        bottom: 0;
        left: 1px;
        width: 93%;
        top: auto;
        margin: 10px;
      }
    }
  }
} // end opc_update__checkout-shipping
